import React, { createContext, useState } from "react"

export interface IModalContext {
  toggleModal: () => void
  showModal: boolean
}

export const ModalContext = createContext<IModalContext | null>(null)

type Props = {
  children?: React.ReactNode
}
const ModalContextProvider: React.FC<Props> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const values: IModalContext = {
    showModal,
    toggleModal,
  }

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  )
}

export default ModalContextProvider
