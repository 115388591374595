import React, { createContext, useState } from "react"

type Locale = "en" | "sv"

export interface ILocaleContext {
  locale: Locale
  updateLocale: (locale: Locale) => void
}

type Props = {
  children?: React.ReactNode
}

/* @ts-ignore */
export const LocaleContext = createContext<ILocaleContext>("sv")

const LocaleContextProvider: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>("sv")

  const updateLocale = (locale: Locale) => {
    setLocale(locale)
  }

  const values: ILocaleContext = {
    locale,
    updateLocale,
  }

  return (
    <LocaleContext.Provider value={values}>{children}</LocaleContext.Provider>
  )
}

export default LocaleContextProvider
